//TODO: decide whether to use this style of initialization tracking, or the
//way outlined in the queue controllers & jobs.tracker.service.js
angular.module('sq.jobs.search.service',
['sq.config', 'sq.jobs.values', 'sq.jobs.tracker.service',
'sq.jobs.events.service', 'sq.jobs.model', 'sq.user.auth'])
.factory('searchState', searchStateFactory);

function searchStateFactory(Jobs, jobValues, jobEvents, Auth, jobTracker,
$rootScope, $log) {
  var searchState = {};
  
  var _idChosen = null;

  Object.defineProperty(searchState, 'idChosen', {
    get: function getIDchosen() {
      return _idChosen;
    },
    set: function setIDchosen(val) {
      _idChosen = !!val && val || null;
    },
  });

  Object.defineProperty(searchState, 'jobBeingViewed', {
    get: function getJobBeingViewed() {
      if (_idChosen) {
        if (jobTracker.jobs.all[searchState.idChosen] ) {
          return jobTracker.jobs.all[searchState.idChosen];
        }
        
        $log.warn(searchState.idChosen + ' doesn\'t look like a real job public id in results.service');
      }
      return null;
    },
  });

  searchState.clearJobs = function clearJobs() {
    _idChosen = null;
  };

  return searchState;
}